import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider } from "@chakra-ui/react"
import './assets/css/index.scss';
import theme from './theme/chakraTheme';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";
import SessionContext, { SessionContextProvider } from './context/SessionContext';
import LoadingScreen from './components/screens/loading/LoadingScreen';
import LoggedInScreen from './components/screens/logged-in/LoggedInScreen';
import LoginScreen from './components/screens/logged-out/LoginScreen';
import OnboardingScreen from './components/screens/logged-out/OnboardingScreen';

function Root() {
  // Render root component. Render routes based on whether the user is logged in.
  const { loggedIn, loading } = useContext(SessionContext);

  // Render loading screen regardless of route if the session context says to
  if (loading) {
    return (
      <LoadingScreen />
    );
  }

  if (false) {
    return (
      <Switch>
        <Route path="/">
        </Route>
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route path="/auth">
          <LoginScreen />
        </Route>
        <Route>
          <Redirect to="/auth/login" />
        </Route>
      </Switch>
    );

  }

};

ReactDOM.render(
  <Router>
    <ChakraProvider theme={theme}>
      <SessionContextProvider>
        <Root />
      </SessionContextProvider>
    </ChakraProvider>
  </Router>,
  document.getElementById('root')
);